<template>
  <a-modal
    title="新建"
    :width="600"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="序号">
              <a-input-number v-decorator="['serial', {initialValue: 1, rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="名称">
              <a-input v-decorator="['name', { rules: [{required: true, message: '必填项，请填写信息'}, {max:10, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="编码">
              <a-input v-decorator="['code', { rules: [{required: true, message: '必填项，请填写信息'}, {max:10, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import moment from 'moment/moment'

// 表单字段
const fields = ['batch']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      }
    }
    return {
      fetching: false,
      form: this.$form.createForm(this),
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleGenerateBatch()
  },
  methods: {
    handleGenerateBatch () {
      const today = moment()
      const year = today.year()
      const month = today.month() + 1 // 注意月份从0开始，所以要加1
      const date = today.date() > 9 ? today.date() : '0' + today.date()
      this.form.setFieldsValue({
        batch: '' + year + month + date
      })
    }
  }
}
</script>
